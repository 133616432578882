import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledDiv = styled.div`
  display: grid;
  grid-template-columns: ${({
    page,
  }) => `[left-margin-start] 1fr [left-margin-end content-start] ${
    page === 'blog'
      ? 'repeat(2, minmax(min-content, 50rem))'
      : page === 'post'
      ? 'repeat(2, minmax(min-content, 35.5rem))'
      : 'minmax(min-content, 128rem)'
  }
    [content-end right-margin-end] 1fr [right-margin-start]`};
  grid-template-rows: minmax(10rem, min-content);
  grid-auto-rows: minmax(10rem, min-content);
  grid-row-gap: ${({ page }) => page === 'blog' && '5rem'};
  & > * {
    grid-column: content-start / content-end;
  }

  ${({ theme: { mq } }) => mq.tablet} {
    display: block;

    & > * {
      ${({ theme: { mq } }) => mq.tablet} {
        ${({ page }) =>
          page === 'blog' || (page === 'post' && 'margin: 0 10rem')}
      }
      ${({ theme: { mq } }) => mq.bigPhone} {
        ${({ page }) =>
          page === 'blog' || (page === 'post' && 'margin: 0 4rem')}
      }
      ${({ theme: { mq } }) => mq.phone} {
        ${({ page }) =>
          page === 'blog' || (page === 'post' && 'margin: 0 2rem')}
      }
    }
  }
`;

const GridView = ({ children, page }) => (
  <StyledDiv page={page}>{children}</StyledDiv>
);

GridView.defaultProps = {
  page: '',
};

GridView.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
  page: PropTypes.string,
};

export default GridView;
